import { novaLocalize } from '../../l10n/localize.js';

const getDefinedStreams = (user, tenant) => {

  const lang = user?.settings?.language;

  const recentlyAdded = {
    displayName: novaLocalize('streams.ordered.recentlyAdded', {}, lang),
    id: 'activeDate',
    type: 'dynamic',
    path: 'recently-added',
    sort: {
      sortProperty: 'activeDate',
      min: new Date(Date.now() + -1669895858).toISOString(), // 2 weeks in milliseconds
      max: new Date(Date.now()).toISOString(),
      direction: 'desc',
    },
    aggregateProperty: {
      date_range: {
        field: 'activeDate',
        ranges: [
          {
            from: 'now-14d/d',
            to: 'now',
          },
        ],
      },
    },
  };

  const startingSoon = {
    displayName: novaLocalize('streams.ordered.startingSoon', {}, lang),
    id: 'startDate',
    type: 'dynamic',
    path: 'starting-soon',
    sort: {
      sortProperty: 'startDate',
      min: new Date(Date.now()).toISOString(),
      max: new Date(Date.now() + 1669895858).toISOString(), // 2 weeks in milliseconds
      direction: 'asc',
    },
    aggregateProperty: {
      date_range: {
        field: 'startDate',
        ranges: [
          {
            from: 'now',
            to: 'now+14d/d',
          },
        ],
      },
    },
  };

  const onDemand = {
    displayName: novaLocalize('general.stream.onDemand', {}, lang),
    id: 'onDemand',
    type: 'dynamic',
    path: 'on-demand',
    property: {
      name: 'startDateType',
      value: 'anytime',
    },
    aggregateProperty: {
      filter: {
        term: {
          startDateType: 'anytime',
        },
      },
    },
  };

  const streamsToReturn = [
    recentlyAdded,
    startingSoon,
    onDemand,
  ];

  if (!tenant?.hasTag('hideFastToCompleteStream')) {
    const fastToComplete = {
      displayName: novaLocalize('streams.property.fastToComplete', {}, lang),
      id: 'fastToComplete',
      type: 'dynamic',
      path: 'fast-to-complete',
      property: {
        name: 'tags',
        value: 'fasttocomplete',
      },
      aggregateProperty: {
        filter: {
          term: {
            tags: 'fasttocomplete',
          },
        },
      },
    };
    streamsToReturn.push(fastToComplete);
  }

  return streamsToReturn;
};

const getDefinedStreamsPath = () => {
  return getDefinedStreams().map(stream => {
    return {
      id: stream.id,
      path: stream.path,
    };
  });
};

export {
  getDefinedStreams,
  getDefinedStreamsPath
};
