import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/card/card-content-meta.js';
import '@brightspace-ui/core/components/card/card.js';
import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui/core/components/dropdown/dropdown-content.js';
import '@brightspace-ui/core/components/dropdown/dropdown-more.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import '@brightspace-ui/core/components/loading-spinner/loading-spinner.js';
import '@brightspace-ui/core/components/menu/menu-item.js';
import '@brightspace-ui/core/components/menu/menu.js';
import '@brightspace-ui/core/components/object-property-list/object-property-list.js';
import '@brightspace-ui/core/components/object-property-list/object-property-list-item.js';

import { bodySmallStyles, bodyStandardStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { html, LitElement, nothing } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import Activity from '../../../../../shared/models/activity/activity.js';
import { activityCardWideStyles } from './styles.js';
import { handleEventBasedOnActivityStatus } from '../../../../../shared/helpers/amplitude.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';
import { NovaAmplitudeMixin } from '../../../../shared/mixins/nova-amplitude-mixin/nova-amplitude-mixin.js';

import '../../general/nova-status-indicator/nova-status-indicator.js';
import '../../skills/skill-chip-list/skill-chip-list.js';
import '../../../../main/components/my-list/my-list.js';

function isMobile() {
  return window.matchMedia('(max-width: 767px)').matches;
}

class ActivityCardWide extends NovaAmplitudeMixin(LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement))))) {

  static get properties() {
    return {
      activity: { type: Object },
      inDemandSkills: { type: Array },
      fill: { type: Boolean, reflect: true, attribute: 'fill' },
      _isMobile: { state: true },
      _providerTenant: { type: Object },
      _skillProfile: { type: Object },
    };
  }

  static get styles() {
    return [
      super.styles,
      bodySmallStyles,
      bodyStandardStyles,
      activityCardWideStyles,
    ];
  }

  constructor() {
    super();

    this.activity = null;
    this.fill = false;
    this._activitySkills = [];
    this._handleWindowResize = this._handleWindowResize.bind(this);
    this._isMobile = isMobile();
    this._providerTenant = null;
  }

  get href() {
    if (!this.skeleton && this.activity?.id) return `/activities/${this.activity.id}`;
    return undefined;
  }

  get _tenantId() {
    return this.session?.tenant?.id;
  }

  get _userGuid() {
    return this.session?.user?.guid;
  }

  get ariaLabel() {
    if (!this.activity) return '';

    const providerText = this._providerTenant ? `${this._providerTenant.name}` : '';

    if (!this.activity?.id) return undefined;

    let skillsSubstring = '';

    switch (this.activity.visibleSkills?.length) {
      case 0:
        skillsSubstring = '';
        break;
      case 1:
        skillsSubstring = this.localize('activity.skills.ariaLabel.exactlyOne', { skillName: this.activity.skills[0].name });
        break;
      case 2:
        skillsSubstring = this.localize('activity.skills.ariaLabel.exactlyTwo', { skillName: this.activity.skills[0].name });
        break;
      default:
        skillsSubstring = this.localize('activity.skills.ariaLabel.threePlus', { skillName: this.activity.skills[0].name, numMoreSkills: this.activity.visibleSkills.length - 1 });
    }

    const ariaLabelContent = [
      this.activity.title,
      providerText,
      this.activity.getTranslatedValue('delivery'),
      this.activity.getTranslatedValue('type'),
      this.activity.getTranslatedValue('duration'),
      skillsSubstring,
    ];

    return ariaLabelContent.reduce(
      (finalString, content, index) => {
        if (index === 0 || content.length === 0) {
          finalString = finalString + content;
        } else {
          finalString = `${finalString}, ${content}`;
        }
        return finalString;
      }, '');
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    this.activity = this.activity ? new Activity(this.activity) : this.activity;
    window.addEventListener('resize', this._handleWindowResize);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('resize', this._handleWindowResize);
  }

  async firstUpdated() {
    super.firstUpdated();
    if (!this.skeleton) {
      this.skeleton = true;
      await this._hydrateProfile();
      this.skeleton = false;
    }
  }

  async updated(_changedProperties) {
    super.updated(_changedProperties);
    let tenantChanged = false;
    for (const [propName, oldValue] of _changedProperties) {
      if (propName === 'activity' && this.activity?.id && oldValue?.provider !== this.activity?.provider) {
        tenantChanged = true;
      }
    }
    if (tenantChanged) await this._updateProviderTenant();
  }

  handleAmplitudeEvent(eventType, additionalAttr = {}) {
    if (this.session.tenant.type === 'admin' || this.session.tenant.type === 'provider') return;
    this.logAmplitudeEvent(eventType, additionalAttr);
  }

  handleOnClick() {
    const eventType = 'recommendedActivitySelected';
    let type = 'Hyped Activity Selected';
    const activityTypeStatus = this.activity?.getActivityStatusLocalizationKey(this.session?._trendingActivities);

    type = handleEventBasedOnActivityStatus(type, activityTypeStatus, this.activity?.startDateStatus);

    if (this.session.hasFeature('skillOnboarding') && window.location.pathname === '/') {
      const eventData = {
        activityTitle: this.activity?.title,
        activityId: this.activity?.id,
        type,
      };
      this.handleAmplitudeEvent(eventType, eventData);
    }
  }

  render() {
    return html`
      <d2l-card
        @click=${this.handleOnClick}
        title=${ifDefined(this.activity?.title)}
        href=${ifDefined(this.href)}
        text=${ifDefined(this.activity?.title)}
        aria-label=${ifDefined(this.ariaLabel)}
        ?skeleton=${this.skeleton}>
          <div slot="header" class="course-image-wrapper d2l-skeletize">
            <img alt="" class="course-image" src=${ifDefined(this.activity?.cardImageUrl)}>
            ${this._indicatorTemplate}
          </div>
          ${this._actionTemplate}
          <div slot="content">
            ${this._contentTemplate}
          </div>
          <div slot="footer">
            ${this._skillsTemplate}
          </div>
      </d2l-card>
    `;
  }

  get _actionTemplate() {
    return html`
      <div slot="actions">
        <my-list .activity=${this.activity} isWideCardComponent></my-list>
      </div>
    `;
  }

  get _contentTemplate() {
    return html`
      <div class="provider-logo-wrapper d2l-skeletize d2l-skeletize-40">
        <img class="provider-logo"
          alt=${this._providerTenant?.name}
          src=${ifDefined(this._providerTenant?.imageUrl)}>
      </div>
      <div class="activity-title-wrapper d2l-skeletize-80 d2l-skeletize">
        <h4 class="activity-title d2l-body-standard">${ifDefined(this.activity?.title)}</h4>
      </div>
      <d2l-card-content-meta>
        ${this._objectPropertiesTemplate}
      </d2l-card-content-meta>
    `;
  }

  get _indicatorTemplate() {
    const localizationKey = this.activity?.getActivityStatusLocalizationKey(this.session?._trendingActivities);

    if (!localizationKey) {
      return nothing;
    }

    return html`
      <nova-status-indicator text=${this.localize(localizationKey)}></nova-status-indicator>
    `;
  }

  get _objectPropertiesTemplate() {
    return !this._isMobile || this.fill
      ? html`
        <d2l-object-property-list>
          <d2l-object-property-list-item id="delivery" text=${ifDefined(this.activity?.getTranslatedValue('delivery'))} ?skeleton=${this.skeleton}></d2l-object-property-list-item>
          <d2l-object-property-list-item id="certificateType" text=${ifDefined(this.activity?.getTranslatedValue('certificateType'))} ?skeleton=${this.skeleton}></d2l-object-property-list-item>
          <d2l-object-property-list-item id="duration" text=${ifDefined(this.activity?.getTranslatedValue('duration'))} ?skeleton=${this.skeleton}></d2l-object-property-list-item>
        </d2l-object-property-list>`
      : html`
      <d2l-object-property-list>
        <d2l-object-property-list-item id="delivery" text=${ifDefined(this.activity?.getTranslatedValue('delivery'))} ?skeleton=${this.skeleton}></d2l-object-property-list-item>
      </d2l-object-property-list>`
    ;
  }

  get _skillsTemplate() {
    const skills = this.activity?.visibleSkills || [];
    if (skills.length === 0) return nothing;

    let modifiedSkills = [];
    if (this._skillProfile) {
      const { roleSkills, setSkills } = this._skillProfile;

      modifiedSkills = skills.map(skill => {
        const newSkill = { ...skill };
        if (setSkills.some(setSkill => setSkill.skillId === skill.id)) {
          newSkill.isInSkillProfile = true;
        }
        if (roleSkills.some(roleSkill => roleSkill.id === skill.id)) {
          newSkill.isRelatedToRole = true;
        }
        return newSkill;
      });
    }

    return html`
      <div class="d2l-skeletize">
        <skill-chip-list
          max-skills=${this._isMobile ? 1 : 3}
          .skills=${modifiedSkills.length > 0 ? modifiedSkills : skills}
          truncate-skill-text
          max-rows="2">
        </skill-chip-list>
      </div>
    `;
  }

  async _hydrateProfile() {
    if (this.session.user.tenantId && this.session.user.guid) {
      try {
        const { skills: setSkills } = await this.client.getSkillProfile(this.session.user.tenantId, this.session.user.guid);
        const skillSubcategoriesForUserRole = await this.client.getSkillSubcategoriesForTitle(this.session?.user.getSetting('selectedTitleId'), this.session.tenant.id ?? this.session.user.tenanId);
        const roleSkills = skillSubcategoriesForUserRole.flatMap(skillSubcategory => skillSubcategory.skills);
        this._skillProfile = { setSkills, roleSkills };
      } catch (e) {
        console.error('Error fetching skill profile: ', e);
        return null;
      }
    } else {
      return null;
    }
  }

  async _updateProviderTenant() {
    this._providerTenant = await this.client.fetchTenant(this.activity.provider);
  }

  _handleWindowResize() {
    this._isMobile = isMobile();
  }
}

window.customElements.define('activity-card-wide', ActivityCardWide);
